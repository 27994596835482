// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import DeliveryEstimator from "../../blocks/DeliveryEstimator/src/DeliveryEstimator";
import ShippingOptions from "../../blocks/ShippingOptions/src/ShippingOptions";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import Followers from "../../blocks/Followers/src/Followers";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Favourites from "../../blocks/Favourites/src/Favourites";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Sms from "../../blocks/Sms/src/Sms";
import Notifications from "../../blocks/Notifications/src/Notifications";
import Location from "../../blocks/Location/src/Location";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import ContentFlag from "../../blocks/ContentFlag/src/ContentFlag";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Wishlist from "../../blocks/Wishlist/src/Wishlist";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Hashtags from "../../blocks/Hashtags/src/Hashtags";
import MenuOrdering from "../../blocks/MenuOrdering/src/MenuOrdering";
import MixpanelIntegration from "../../blocks/MixpanelIntegration/src/MixpanelIntegration";
import BitcoinPayment from "../../blocks/BitcoinPayment/src/BitcoinPayment";
import Settings from "../../blocks/Settings/src/Settings";
import CustomisableUserSubscriptions from "../../blocks/CustomisableUserSubscriptions/src/CustomisableUserSubscriptions";
import LoyaltySystem from "../../blocks/LoyaltySystem/src/LoyaltySystem";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import HelpCentre from "../../blocks/HelpCentre/src/HelpCentre";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import ProjecttaskTracking from "../../blocks/ProjecttaskTracking/src/ProjecttaskTracking";
import DonationPayments from "../../blocks/DonationPayments/src/DonationPayments";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import AutomaticCheckoutCalculation from "../../blocks/AutomaticCheckoutCalculation/src/AutomaticCheckoutCalculation";
import Maps from "../../blocks/Maps/src/Maps";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Share from "../../blocks/Share/src/Share";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import FeatureSettings from "../../blocks/FeatureSettings/src/FeatureSettings";
import QuickbooksIntegration from "../../blocks/QuickbooksIntegration/src/QuickbooksIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import RecommendationEngine from "../../blocks/RecommendationEngine/src/RecommendationEngine";
import ProductTutorial from "../../blocks/ProductTutorial/src/ProductTutorial";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import Sorting from "../../blocks/sorting/src/Sorting";
import Gallery from "../../blocks/Gallery/src/Gallery";
import Reviews from "../../blocks/Reviews/src/Reviews";
import Search from "../../blocks/Search/src/Search";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import Library from "../../blocks/Library/src/Library";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import CfSubcartsForShoppingCart from "../../blocks/CfSubcartsForShoppingCart/src/CfSubcartsForShoppingCart";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Payments from "../../blocks/Payments/src/Payments";
import CfExchangeablePointsrewardsSystem from "../../blocks/CfExchangeablePointsrewardsSystem/src/CfExchangeablePointsrewardsSystem";
import Comments from "../../blocks/Comments/src/Comments";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import Videos from "../../blocks/Videos/src/Videos";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import BlockUsers from "../../blocks/BlockUsers/src/BlockUsers";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import LocalListingAds from "../../blocks/LocalListingAds/src/LocalListingAds";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import TaskList from "../../blocks/TaskList/src/TaskList";
import ConversationThreading from "../../blocks/ConversationThreading/src/ConversationThreading";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";



const routeMap = {
ContentModeration:{
 component:ContentModeration,
path:"/ContentModeration"},
StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},
DeliveryEstimator:{
 component:DeliveryEstimator,
path:"/DeliveryEstimator"},
ShippingOptions:{
 component:ShippingOptions,
path:"/ShippingOptions"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
Followers:{
 component:Followers,
path:"/Followers"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
Sms:{
 component:Sms,
path:"/Sms"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Location:{
 component:Location,
path:"/Location"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Wishlist:{
 component:Wishlist,
path:"/Wishlist"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
MenuOrdering:{
 component:MenuOrdering,
path:"/MenuOrdering"},
MixpanelIntegration:{
 component:MixpanelIntegration,
path:"/MixpanelIntegration"},
BitcoinPayment:{
 component:BitcoinPayment,
path:"/BitcoinPayment"},
Settings:{
 component:Settings,
path:"/Settings"},
CustomisableUserSubscriptions:{
 component:CustomisableUserSubscriptions,
path:"/CustomisableUserSubscriptions"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
ProjecttaskTracking:{
 component:ProjecttaskTracking,
path:"/ProjecttaskTracking"},
DonationPayments:{
 component:DonationPayments,
path:"/DonationPayments"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
Maps:{
 component:Maps,
path:"/Maps"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Share:{
 component:Share,
path:"/Share"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
FeatureSettings:{
 component:FeatureSettings,
path:"/FeatureSettings"},
QuickbooksIntegration:{
 component:QuickbooksIntegration,
path:"/QuickbooksIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
ProductTutorial:{
 component:ProductTutorial,
path:"/ProductTutorial"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
Search:{
 component:Search,
path:"/Search"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
Library:{
 component:Library,
path:"/Library"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
CfSubcartsForShoppingCart:{
 component:CfSubcartsForShoppingCart,
path:"/CfSubcartsForShoppingCart"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Payments:{
 component:Payments,
path:"/Payments"},
CfExchangeablePointsrewardsSystem:{
 component:CfExchangeablePointsrewardsSystem,
path:"/CfExchangeablePointsrewardsSystem"},
Comments:{
 component:Comments,
path:"/Comments"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
Videos:{
 component:Videos,
path:"/Videos"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
BlockUsers:{
 component:BlockUsers,
path:"/BlockUsers"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
LocalListingAds:{
 component:LocalListingAds,
path:"/LocalListingAds"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
ConversationThreading:{
 component:ConversationThreading,
path:"/ConversationThreading"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},

  Home: {
component:PhoneNumberInput,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
